// Copyright (C) 2024 Xtremis, All rights reserved

export const API_URL = process.env.REACT_APP_SPECTRUM_API_URL || "http://localhost:5151";

export const MAIN_CANVAS_VERTICAL_AXIS_SIZE: number = 60;
export const MAIN_CANVAS_HORIZONTAL_AXIS_SIZE: number = 55;
export const HORIZONTAL_AXIS_HEIGHT: number = 30;
export const LONG_VOXEL_PANEL_HEIGHT = 20;
export const MIN_BIN_WIDTH = 4; // Minimum width for each bin
export const PSD_HEIGHT: number = 250;
export const hertz_divider = 1000000;
export const hertz_unit = "(MHz)";

export const purple = "rgba(127, 17, 224, 1)";
export const white = "rgba(255, 255, 255, 1.0)";
export const white_box_background = "rgba(255, 255, 255, 0.2)";
export const axis_cross_line_color = "#d3d3d3";

export const ALMOST_ZERO = 1e-6;
export const HISTORY_SIZE = 30;
export const MAX_ZOOM: number = 15;

export const HANDLE_SIZE = 6;
export const HALF_HANDLE_SIZE = HANDLE_SIZE / 2;

export const AUTO_SCROLL_SPEED: number = 10;

export const version = "0.1.6";

export const DEFAULT_VOXEL_INFO: VoxelInfo = {
    modulation: "BPSK",
    mod_variant: undefined,
    emitter_id: 0,
    receive_power_dbm: [],
    confidence: 1.0,
    name: undefined,
    subvoxels: undefined,
    delay_spread: undefined,
    delay_seed: undefined,
    comment: ""
};
