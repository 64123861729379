// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useRef, useEffect } from "react";
import { useCacheStore } from "../utils/store";
import { LONG_VOXEL_PANEL_HEIGHT, MAIN_CANVAS_VERTICAL_AXIS_SIZE } from "../utils/constants";
import "./VerticalAxis.css";
import { formatAsHHMMSS } from "../utils/utils";

const VerticalAxis: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const heatmapData = useCacheStore(state => state.heatmapData);
    const visibleArea = useCacheStore(state => state.visibleArea);
    const canvasSize = useCacheStore(state => state.canvasSize);


    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        if (canvas.height !== canvasSize.height) {
            // we have some space above and under the "canvas"
            canvas.height = LONG_VOXEL_PANEL_HEIGHT + canvasSize.height + LONG_VOXEL_PANEL_HEIGHT;
            canvas.width = MAIN_CANVAS_VERTICAL_AXIS_SIZE;
        }

        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        if (!heatmapData) return;

        ctx.clearRect(0, 0, MAIN_CANVAS_VERTICAL_AXIS_SIZE, canvasSize.height);

        ctx.fillStyle = "#fff";
        ctx.strokeStyle = "#fff";
        ctx.font = "11px Arial";
        ctx.textAlign = "right";
        ctx.textBaseline = "top";

        ctx.font = "10.5px Arial";
        ctx.fillText("(hh:mm:ss.s)", MAIN_CANVAS_VERTICAL_AXIS_SIZE, 0);
        ctx.font = "11px Arial";

        const totalTicks = Math.ceil(canvasSize.height / 25);
        const visibleTimeRange =
            heatmapData.start_time +
            (visibleArea.toRow / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time) -
            (heatmapData.start_time + (visibleArea.fromRow / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time));

        // Quantize step size
        const tickStep = quantizeStep(visibleTimeRange / (totalTicks - 1));

        const startValue = heatmapData.end_time - (visibleArea.fromRow / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time);

        // Calculate and render ticks
        for (let i = 0; i < totalTicks; i++) {
            const tickValue = startValue - i * tickStep;

            const y = (i * tickStep * canvasSize.height) / visibleTimeRange + LONG_VOXEL_PANEL_HEIGHT;
            if (y >= 0 && y <= canvasSize.height + LONG_VOXEL_PANEL_HEIGHT) {
                // Draw tick line
                ctx.beginPath();
                ctx.moveTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE - 6, y);
                ctx.lineTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, y);
                ctx.stroke();

                // Draw tick label
                ctx.fillText(formatAsHHMMSS(tickValue), MAIN_CANVAS_VERTICAL_AXIS_SIZE - 8, y);
            }
        }
    }, [visibleArea, canvasSize.height]);

    const quantizeStep = (step: number): number => {
        const STEP_DIVIDER = 10;
        return Math.ceil(step * STEP_DIVIDER) / STEP_DIVIDER;
    };

    return (
        <canvas
            ref={canvasRef}
            className="vertical-axis-container"
            style={{
                height: `calc(100% + ${2 * LONG_VOXEL_PANEL_HEIGHT}px)`,
                marginTop: `-${LONG_VOXEL_PANEL_HEIGHT}px`
            }}
        />
    );
};

export default VerticalAxis;
