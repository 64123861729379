// Copyright (C) 2024 Xtremis, All rights reserved

import {
    purple,
    HALF_HANDLE_SIZE,
    HANDLE_SIZE,
    white,
    white_box_background,
    axis_cross_line_color
} from "./constants";

const alphaMin = 0;
const alphaMax = 0.4;

function colorWithOpacity(color: string, alpha: number): string {
    // Simple example with two known color strings:
    // Adjust or expand for your actual color usage
    if (color === purple) {
        // purple => (128,0,128)
        return `rgba(128,0,128,${alpha})`;
    }
    if (color === white) {
        // white => (255,255,255)
        return `rgba(255,255,255,${alpha})`;
    }
    if (color === white_box_background) {
        // same as white, or differ if needed
        return `rgba(255,255,255,${alpha})`;
    }
    // fallback
    return color; // or `rgba(0,0,0,${alpha})`
}

export const getHeatmapCell = (
    x: number,
    y: number,
    offset: { x: number; y: number },
    scale: { x: number; y: number },
    heatmapData: { width: number; height: number }
) => {
    const col = (x - offset.x) / scale.x;
    const row = (y - offset.y) / scale.y;
    if (col >= 0 && col < heatmapData.width && row >= 0 && row < heatmapData.height) {
        return { col, row };
    }
    return null;
};

export const drawBoundingBox = (
    ctx: CanvasRenderingContext2D,
    start: { col: number; row: number },
    end: { col: number; row: number },
    scale: Scale,
    offset: Offset,
    color: string,
    drawing: DrawingType = "continuous",
    voxelOpacity: boolean
) => {
    const startX = Math.min(start.col, end.col) * scale.x + offset.x;
    const startY = Math.min(start.row, end.row) * scale.y + offset.y;
    const endX = Math.max(start.col, end.col) * scale.x + offset.x;
    const endY = Math.max(start.row, end.row) * scale.y + offset.y;

    if(drawing === "dashed")
        ctx.setLineDash([10,5]);
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.strokeRect(startX, startY, endX - startX, endY - startY);
    ctx.setLineDash([]);
    const alpha = voxelOpacity ? alphaMax : alphaMin;
    ctx.fillStyle  = colorWithOpacity(white_box_background, alpha);
    ctx.fillRect(startX, startY, endX - startX, endY - startY);
};

export const drawVoxels = (
    ctx: CanvasRenderingContext2D,
    bbox: BoundingBox,
    scale: Scale,
    offset: Offset,
    centerY: number,
    resizingState: {
        bboxId: number;
        corner: HoverCorner;
    } | null,
    hovewhiteCorner: {
        bboxId: number;
        corner: HoverCorner;
    } | null,
    voxelOpacity: boolean
) => {
    const isResizing = resizingState && resizingState.bboxId === bbox.id;

    let color = white;
    if (bbox.selected || isResizing) {
        color = purple;
    }

    drawBoundingBox(ctx, bbox.start, bbox.end, scale, offset, color, bbox.drawing, voxelOpacity);

    const bboxStartX = bbox.start.col * scale.x + offset.x;
    const bboxEndX = bbox.end.col * scale.x + offset.x;
    const bboxStartY = bbox.start.row * scale.y + offset.y;
    const bboxEndY = bbox.end.row * scale.y + offset.y;

    if (bbox.type === "longVoxel") {
        const fillStyle = bbox.selected || isResizing ? purple : white;
        drawLongVoxel(ctx, fillStyle, centerY, bboxStartX, bboxEndX, voxelOpacity);
    } else if (bbox.type === "rectangle") {
        drawLRectangle(
            ctx,
            isResizing,
            bbox.selected,
            bbox.id,
            bboxStartX,
            bboxStartY,
            bboxEndX,
            bboxEndY,
            hovewhiteCorner
        );
    }
};

export const drawCrossLines = (
    ctx: CanvasRenderingContext2D,
    image: HTMLImageElement,
    hoverCoords: Coords,
    scale: Scale,
    clampedOffsetX: number,
    clampedOffsetY: number
): void => {
    if (!hoverCoords) {
        return;
    }

    const scaledWidth = image.width * scale.x;
    if (hoverCoords.y >= 0 && hoverCoords.y < image.height) {
        const hoverY = hoverCoords.y * scale.y + clampedOffsetY;
        drawHorizontalLine(ctx, hoverY, clampedOffsetX, scaledWidth);
    }

    const scaledHeight = image.height * scale.y;
    if (hoverCoords.x >= 0 && hoverCoords.x < image.width) {
        const hoverX = hoverCoords.x * scale.x + clampedOffsetX;
        drawVerticalLine(ctx, hoverX, clampedOffsetY, scaledHeight);
    }
};

const drawHorizontalLine = (ctx: CanvasRenderingContext2D, y: number, offsetX: number, width: number): void => {
    ctx.strokeStyle = axis_cross_line_color;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(offsetX, y);
    ctx.lineTo(offsetX + width, y);
    ctx.stroke();
};

export const drawVerticalLine = (ctx: CanvasRenderingContext2D, x: number, offsetY: number, height: number): void => {
    ctx.strokeStyle = axis_cross_line_color;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(x, offsetY);
    ctx.lineTo(x, offsetY + height);
    ctx.stroke();
};

export const drawLongVoxel = (
    ctx: CanvasRenderingContext2D,
    fillStyle: string,
    centerY: number,
    bboxStartX: number,
    bboxEndX: number,
    voxelOpacity: boolean
): void => {
    const alpha = voxelOpacity ? alphaMax : alphaMin;

    if (fillStyle === "purple") {
        ctx.fillStyle = `rgba(128,0,128,${alpha})`;
    } else if (fillStyle === "white") {
        ctx.fillStyle = `rgba(255,255,255,${alpha})`;
    } else {
        // fallback
        ctx.fillStyle = fillStyle;
    }

    ctx.fillRect(
        bboxStartX - HALF_HANDLE_SIZE,
        centerY - HALF_HANDLE_SIZE,
        HANDLE_SIZE,
        HANDLE_SIZE
    );
    ctx.fillRect(
        bboxEndX - HALF_HANDLE_SIZE,
        centerY - HALF_HANDLE_SIZE,
        HANDLE_SIZE,
        HANDLE_SIZE
    );
};


export const drawLRectangle = (
    ctx: CanvasRenderingContext2D,
    isResizing: boolean | null,
    selected: boolean,
    boxId: number,
    bboxStartX: number,
    bboxStartY: number,
    bboxEndX: number,
    bboxEndY: number,
    hovewhiteCorner: {
        bboxId: number;
        corner: HoverCorner;
    } | null
): void => {
    // Corners
    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "top-left") ? purple : white;
    ctx.fillRect(bboxStartX - HALF_HANDLE_SIZE, bboxStartY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "bottom-right") ? purple : white;
    ctx.fillRect(bboxEndX - HALF_HANDLE_SIZE, bboxEndY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    // Midpoints
    const midX = (bboxStartX + bboxEndX) / 2;
    const midY = (bboxStartY + bboxEndY) / 2;

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "top-mid") ? purple : white;
    ctx.fillRect(midX - HALF_HANDLE_SIZE, bboxStartY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "bottom-mid") ? purple : white;
    ctx.fillRect(midX - HALF_HANDLE_SIZE, bboxEndY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "left-mid") ? purple : white;
    ctx.fillRect(bboxStartX - HALF_HANDLE_SIZE, midY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "right-mid") ? purple : white;
    ctx.fillRect(bboxEndX - HALF_HANDLE_SIZE, midY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);
};

const isActive = (
    hovewhiteCorner: {
        bboxId: number;
        corner: HoverCorner;
    } | null,
    boxId: number,
    selected: boolean,
    isResizing: boolean | null,
    controlPointName: string
): boolean | null => {
    return (
        selected ||
        isResizing ||
        (hovewhiteCorner && hovewhiteCorner.bboxId === boxId && hovewhiteCorner.corner === controlPointName)
    );
};
