/* Copyright (C) 2024 Xtremis, All rights reserved*/

import React, { useRef, useState, useEffect, useCallback } from "react";
import {
    calculateBoundingBoxStatistics,
    calculateMousePosition,
    calculateVisibleArea,
    createBluishImage,
    imageDataToBlob,
    isWithinImageBound,
    normalizeData,
    sideToHoverCorner,
    updateBoxByCorner
} from "../utils/utils";
import { getHeatmapCell, drawVoxels, drawCrossLines } from "../utils/drawutils";
import { useCacheStore } from "../utils/store";
import "./MainCanvas.css";
import { handleWheel } from "./MainCanvasHandlers";
import { ALMOST_ZERO, AUTO_SCROLL_SPEED, DEFAULT_VOXEL_INFO } from "../utils/constants";

interface CanvasProps {
    imageData: Int8Array;
    imageWidth: number;
    imageHeight: number;
}

const MainCanvas: React.FC<CanvasProps> = ({ imageData, imageWidth, imageHeight }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const isAutoScollAnimationOn = useRef<number | null>(null);
    const mousePosition = useRef({ x: 0, y: 0 });

    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const isPanning = useRef(false);
    const panStart = useRef({ x: 0, y: 0 });

    const heatmapData = useCacheStore(state => state.heatmapData);
    const hoverCoords = useCacheStore(state => state.hoverCoords);
    const setHoverCoords = useCacheStore(state => state.setHoverCoords);
    const setVisibleArea = useCacheStore(state => state.setVisibleArea);
    const canvasSize = useCacheStore(state => state.canvasSize);
    const setCanvasSize = useCacheStore(state => state.setCanvasSize);
    const scale = useCacheStore(state => state.scale);
    const offset = useCacheStore(state => state.offset);
    const onScaleChange = useCacheStore(state => state.setScale);
    const onOffsetChange = useCacheStore(state => state.setOffset);
    const scaleOffsetInitialized = useCacheStore(state => state.scaleOffsetInitialized);
    const setScaleOffsetInitialized = useCacheStore(state => state.setScaleOffsetInitialized);
    const voxels = useCacheStore(state => state.voxels);
    const persistVoxels = useCacheStore(state => state.persistVoxels);
    const updateVoxel = useCacheStore(state => state.updateVoxel);
    const getNextBoxId = useCacheStore(state => state.getNextBoxId);
    const setVoxelSelected = useCacheStore(state => state.setVoxelSelected);
    const deselectVoxels = useCacheStore(state => state.deselectVoxels);
    const deleteSelectedVoxel = useCacheStore(state => state.deleteSelectedVoxel);
    const undo = useCacheStore(state => state.undo);
    const minDb = useCacheStore(state => state.minDb);
    const maxDb = useCacheStore(state => state.maxDb);
    const tempHighlightBox = useCacheStore(state => state.tempHighlightBox);
    const setTempHighlightBox = useCacheStore(state => state.setTempHighlightBox);
    const canvasMode = useCacheStore(state => state.canvasMode);
    const setCanvasMode = useCacheStore(state => state.setCanvasMode);
    const selectedAntenna = useCacheStore(state => state.selectedAntenna);
    const selectedAggregation = useCacheStore(state => state.selectedAggregation);
    const showVoxels = useCacheStore(state => state.showVoxels);
    const editingMode = useCacheStore(state => state.editingMode);
    const voxelOpacity = useCacheStore(state => state.voxelOpacity);


    // State for bounding boxes
    const [newBoxId, setNewBoxId] = useState<number | null>(null);

    const [hoveredBBox, setHoveredBBox] = useState<{
        position: { x: number; y: number };
        stats: HoveredStats;
    } | null>(null);

    const [hoveredCorner, setHoveredCorner] = useState<{
        bboxId: number;
        corner: HoverCorner;
    } | null>(null);

    const [resizingState, setResizingState] = useState<{
        bboxId: number;
        corner: HoverCorner;
    } | null>(null);

    const [hoveredSide, setHoveredSide] = useState<{
        bboxId: number;
        side: Directions;
    } | null>(null);

    useEffect(() => {
        let timeoutId: number | null = null;
        const canvasElement = canvasRef.current;
        if (!canvasElement) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                timeoutId = window.setTimeout(() => {
                    if (canvasElement.width !== Math.floor(width) || canvasElement.height !== Math.floor(height)) {
                        canvasElement.width = width;
                        canvasElement.height = height;
                        setCanvasSize({ width, height });

                        const minScaleX = canvasElement.width / imageWidth;
                        const minScaleY = canvasElement.height / imageHeight;

                        const newScaleX = Math.max(scale.x, minScaleX);
                        const newScaleY = Math.max(scale.y, minScaleY);

                        onScaleChange({ x: newScaleX, y: newScaleY });
                    }
                }, 5);
            }
        });
        resizeObserver.observe(canvasElement);
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            resizeObserver.disconnect();
        };
    }, []);

    const drawImage = useCallback(() => {
        if (!canvasRef.current || !image) return;
        if (!scale || !offset) return;

        const ctx = canvasRef.current.getContext("2d");
        if (!ctx) return;

        ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);

        // Calculate scaled dimensions and clamp offsets
        const scaledWidth = image.width * scale.x;
        const scaledHeight = image.height * scale.y;
        const clampedOffsetX = Math.max(Math.min(offset.x, 0), canvasSize.width - scaledWidth);
        const clampedOffsetY = Math.max(Math.min(offset.y, 0), canvasSize.height - scaledHeight);

        ctx.imageSmoothingEnabled = false;

        // Draw the main heatmap image
        ctx.drawImage(image, clampedOffsetX, clampedOffsetY, scaledWidth, scaledHeight);

        // Draw stored voxels if showVoxels is true
        if (showVoxels) {
            const centerY = canvasSize.height / 2;
            voxels.forEach((bbox) => {
                drawVoxels(ctx, bbox, scale, offset, centerY, resizingState, hoveredCorner, voxelOpacity);
            });
        }

        // If in non-editing mode but showVoxels is true, draw the ephemeral tempHighlightBox
        if (!editingMode && showVoxels && tempHighlightBox) {
            const centerY = canvasSize.height / 2;
            drawVoxels(ctx, tempHighlightBox, scale, offset, centerY, resizingState, hoveredCorner, voxelOpacity);
        }

        // Optionally draw crosshair lines or other overlays

        drawCrossLines(ctx, image, hoverCoords, scale, clampedOffsetX, clampedOffsetY);
    }, [
        canvasRef,
        image,
        scale,
        offset,
        canvasSize,
        showVoxels,
        voxels,
        tempHighlightBox,
        editingMode,
        resizingState,
        hoveredCorner,
        hoverCoords,
        voxelOpacity
    ]);

    useEffect(() => {
        // Reset resizingState if bboxId is invalid
        if (resizingState && !voxels.find((bbox) => bbox.id === resizingState.bboxId)) {
            setResizingState(null);
        }

        // Reset hoveredCorner if bboxId is invalid
        if (hoveredCorner && !voxels.find((bbox) => bbox.id === hoveredCorner.bboxId)) {
            setHoveredCorner(null);
        }
    }, [resizingState, hoveredCorner]);

    useEffect(() => {
        if (!image || canvasSize.width === 0 || canvasSize.height === 0) return;
        if (!scaleOffsetInitialized) {
            const minScaleX = canvasSize.width / image.width;
            const minScaleY = canvasSize.height / image.height;

            // Set scale to fill the canvas
            const newScaleX = minScaleX;
            const newScaleY = minScaleY;

            onScaleChange({ x: newScaleX, y: newScaleY });

            // Center the image
            const scaledWidth = image.width * newScaleX;
            const scaledHeight = image.height * newScaleY;

            const offsetX = (canvasSize.width - scaledWidth) / 2;
            const offsetY = (canvasSize.height - scaledHeight) / 2;

            onOffsetChange({ x: offsetX, y: offsetY });
            setScaleOffsetInitialized(true);
        }
    }, [image, canvasSize.width, canvasSize.height, onScaleChange, onOffsetChange]);

    useEffect(() => {
        async function processAndRenderImage(imageData: Int8Array, width: number, height: number) {
            const normalized = normalizeData(imageData, minDb, maxDb);
            const bluish = createBluishImage(normalized, width, height);
            const blob = await imageDataToBlob(bluish);
            const blobUrl = URL.createObjectURL(blob);

            const img = new Image();
            img.src = blobUrl;
            img.onload = () => {
                if (!canvasRef.current) return;
                setImage(img);
                URL.revokeObjectURL(blobUrl);
            };
        }

        processAndRenderImage(imageData, imageWidth, imageHeight).catch((error) => {
            console.error("Error processing image data:", error);
        });
    }, [imageData, imageWidth, imageHeight, minDb, maxDb]);

    useEffect(() => {
        if (!image) return;
        if (!scale || !offset) return;

        const {
            visibleFromX,
            visibleToX,
            visibleFromY,
            visibleToY
        } = calculateVisibleArea(image.width, image.height, scale, offset, canvasSize);

        setVisibleArea({
            fromRow: Math.floor(visibleFromY),
            toRow: Math.ceil(visibleToY),
            fromCol: Math.floor(visibleFromX),
            toCol: Math.ceil(visibleToX)
        });
    }, [offset, scale, image, canvasSize, setVisibleArea]);

    useEffect(() => {
        drawImage();
    }, [
        image,
        scale,
        offset,
        newBoxId,
        voxels,
        showVoxels,
        canvasSize,
        hoverCoords,
        hoveredCorner,
        resizingState,
        tempHighlightBox,
        voxelOpacity
    ]);

    const updateNewBox = (mouseX: number, mouseY: number, offsetUpdated: Offset | null = null) => {
        if (!heatmapData) return;

        const currentOffset = offsetUpdated ? offsetUpdated : offset;
        const cell = getHeatmapCell(mouseX, mouseY, currentOffset, scale, heatmapData);
        if (cell) {
            const currentVoxel = voxels.find((bbox) => bbox.id === newBoxId);
            if (currentVoxel) {
                updateVoxel({ ...currentVoxel, end: cell });
            }
        }
    };

    const updateBoxStates = (mouseX: number, mouseY: number, offsetUpdated: Offset | null = null) => {
        if (!resizingState || !heatmapData) return;

        const currentOffset = offsetUpdated ? offsetUpdated : offset;
        const { bboxId, corner } = resizingState;
        const bboxIndex = voxels.findIndex((bbox) => bbox.id === bboxId);
        if (bboxIndex === -1) {
            setResizingState(null);
            return;
        }
        const bbox = voxels[bboxIndex];

        const cell = getHeatmapCell(mouseX, mouseY, currentOffset, scale, heatmapData);
        if (cell) {
            const updatedBBox = structuredClone(bbox);
            // Resizing logic for both rectangle and longVoxel now similar
            updateBoxByCorner(corner, updatedBBox, cell);
            updateVoxel({ ...updatedBBox, selected: true });
        }
        setHoveredBBox(null);
    };

    const updateTempHighlightBox = (mouseX: number, mouseY: number, offsetUpdated: Offset | null = null) => {
        if (!heatmapData || !tempHighlightBox) return;

        const currentOffset = offsetUpdated ? offsetUpdated : offset;
        const cell = getHeatmapCell(mouseX, mouseY, currentOffset, scale, heatmapData);
        if (cell) {
            setTempHighlightBox({ ...tempHighlightBox, end: cell });
        }
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!scale || !offset || !heatmapData) return;

        setCanvasMode("DRAWING");

        const rect = canvasRef.current!.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        // Left mouse button
        if (e.button === 0 && showVoxels) {
            if (hoveredCorner) {
                const { bboxId, corner } = hoveredCorner;
                setVoxelSelected(bboxId);
                if (editingMode) setResizingState({ bboxId, corner });
            } else if (hoveredSide) {
                const { bboxId, side } = hoveredSide;
                setVoxelSelected(bboxId);
                // Convert side to a HoverCorner (e.g., 'top' -> 'top-mid')
                if (editingMode) {
                    const cornerEquivalent: HoverCorner = sideToHoverCorner(side);
                    setResizingState({ bboxId, corner: cornerEquivalent });
                }
            } else {
                deselectVoxels();
                const cell = getHeatmapCell(mouseX, mouseY, offset, scale, heatmapData);
                if (cell) {
                    if (editingMode) {
                        const newId = getNextBoxId();
                        updateVoxel({
                            ...DEFAULT_VOXEL_INFO,
                            id: newId,
                            start: cell,
                            end: cell,
                            type: "rectangle",
                            drawing: "continuous",
                            selected: true
                        });
                        setNewBoxId(newId);
                    } else {
                        setTempHighlightBox({
                            ...DEFAULT_VOXEL_INFO,
                            id: -1,
                            start: cell,
                            end: cell,
                            type: "rectangle",
                            drawing: "continuous",
                            selected: true
                        });
                    }
                }
            }
        }
        // Right mouse button => Start panning
        else if (e.button === 2) {
            isPanning.current = true;
            panStart.current = {
                x: e.clientX - offset.x,
                y: e.clientY - offset.y
            };
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!canvasRef.current || !scale || !offset || !heatmapData || !image) return;
        const rect = canvasRef.current.getBoundingClientRect();
        const { canvasX, canvasY, mouseX, mouseY } = calculateMousePosition(e, rect, offset, scale);

        // Update hover coordinates
        if (isWithinImageBound(canvasX, canvasY, imageWidth, imageHeight)) {
            setHoverCoords({
                x: canvasX,
                y: canvasY
            });
        }

        // Handle resizing
        if (resizingState && editingMode) {
            updateBoxStates(mouseX, mouseY);
            return;
        }

        // Handle panning
        if (isPanning.current) {
            const newOffsetX = e.clientX - panStart.current.x;
            const newOffsetY = e.clientY - panStart.current.y;

            const scaledWidth = image.width * scale.x || 0;
            const scaledHeight = image.height * scale.y || 0;

            const clampedX = Math.max(Math.min(newOffsetX, 0), canvasSize.width - scaledWidth);
            const clampedY = Math.max(Math.min(newOffsetY, 0), canvasSize.height - scaledHeight);

            onOffsetChange({ x: clampedX, y: clampedY });
            setHoveredBBox(null);
            return;
        }

        //If editing: draw or update a new box in the store
        if (editingMode) {
            if (newBoxId) {
                updateNewBox(mouseX, mouseY);
            }
        }
        // Otherwise (non-editing): draw/update ephemeral tempHighlightBox
        else if (tempHighlightBox) {
            updateTempHighlightBox(mouseX, mouseY);
        }

        // Detect hover near bounding boxes
        const hoverThreshold = 5; // Pixels around the corner or side
        let cornerHovered = false;
        let sideHovered = false;

        const calculateStatsAndSetHoveredBBox = (bbox: BoundingBox, position: { x: number; y: number }) => {
            const stats = calculateBoundingBoxStatistics(
                bbox.start,
                bbox.end,
                bbox.type,
                heatmapData,
                selectedAntenna,
                selectedAggregation
            );
            if (stats !== null) {
                setHoveredBBox({
                    position,
                    stats: stats
                });
            }
        };

        const handleHover = (
            bbox: BoundingBox,
            mouseX: number,
            mouseY: number,
            x: number,
            y: number,
            cornerName: HoverCorner
        ) => {
            if (Math.abs(mouseX - x) <= hoverThreshold && Math.abs(mouseY - y) <= hoverThreshold) {
                setHoveredCorner({ bboxId: bbox.id, corner: cornerName });
                cornerHovered = true;
                calculateStatsAndSetHoveredBBox(bbox, { x, y });
                return true;
            }
            return false;
        };

        setHoveredSide(null);

        voxels.forEach((bbox) => {
            const bboxStartX = bbox.start.col * scale.x + offset.x;
            const bboxStartY = bbox.start.row * scale.y + offset.y;
            const bboxEndX = bbox.end.col * scale.x + offset.x;
            const bboxEndY = bbox.end.row * scale.y + offset.y;

            const hoverCheck = (
                mousePos: number,
                boxPos: number,
                mouseSide: number,
                sideStart: number,
                sideEnd: number,
                direction: Directions
            ) => {
                if (
                    Math.abs(mousePos - boxPos) <= hoverThreshold &&
                    mouseSide > sideStart + hoverThreshold &&
                    mouseSide < sideEnd - hoverThreshold
                ) {
                    setHoveredSide({ bboxId: bbox.id, side: direction });
                    sideHovered = true;
                    return;
                }
            };
            if (bbox.type === "longVoxel") {
                const midY = (bboxStartY + bboxEndY) / 2;

                // Check left and right handles (corners for longVoxel)
                if (handleHover(bbox, mouseX, mouseY, bboxStartX, midY, "left")) return;
                if (handleHover(bbox, mouseX, mouseY, bboxEndX, midY, "right")) return;

                // If not corners/midpoints, check sides for longVoxel
                if (!cornerHovered) {
                    hoverCheck(mouseX, bboxStartX, mouseY, bboxStartY, bboxEndY, "left");
                    hoverCheck(mouseX, bboxEndX, mouseY, bboxStartY, bboxEndY, "right");
                }
            } else {
                // Rectangle logic as before
                if (handleHover(bbox, mouseX, mouseY, bboxStartX, bboxStartY, "top-left")) return;
                if (handleHover(bbox, mouseX, mouseY, bboxEndX, bboxEndY, "bottom-right")) return;

                const midX = (bboxStartX + bboxEndX) / 2;
                const midY = (bboxStartY + bboxEndY) / 2;

                if (handleHover(bbox, mouseX, mouseY, midX, bboxStartY, "top-mid")) return;
                if (handleHover(bbox, mouseX, mouseY, midX, bboxEndY, "bottom-mid")) return;
                if (handleHover(bbox, mouseX, mouseY, bboxStartX, midY, "left-mid")) return;
                if (handleHover(bbox, mouseX, mouseY, bboxEndX, midY, "right-mid")) return;

                // Check sides for rectangle
                if (!cornerHovered) {
                    // top side
                    hoverCheck(mouseY, bboxStartY, mouseX, bboxStartX, bboxEndX, "top");
                    // bottom side
                    hoverCheck(mouseY, bboxEndY, mouseX, bboxStartX, bboxEndX, "bottom");
                    // left side
                    hoverCheck(mouseX, bboxStartX, mouseY, bboxStartY, bboxEndY, "left");
                    // right side
                    hoverCheck(mouseX, bboxEndX, mouseY, bboxStartY, bboxEndY, "right");
                }
            }
        });

        // Update cursor and tooltip based on what is hovered
        if (cornerHovered && showVoxels) {
            // Stats and highlight are already shown in handleHover
            if (canvasRef.current && hoveredCorner) {
                switch (hoveredCorner.corner) {
                    case "top-mid":
                    case "bottom-mid":
                        if (editingMode) {
                            canvasRef.current.style.cursor = "ns-resize";
                        }
                        break;
                    case "left-mid":
                    case "right-mid":
                    case "left":
                    case "right":
                        if (editingMode) {
                            canvasRef.current.style.cursor = "ew-resize";
                        }
                        break;
                    default:
                        if (editingMode) {
                            canvasRef.current.style.cursor = "nwse-resize";
                        }
                        break;
                }
            }
        } else if (sideHovered) {
            setHoveredCorner(null);

            // Show bounding box stats for sides as well
            if (hoveredSide) {
                const { bboxId, side } = hoveredSide;
                const bbox = voxels.find((b) => b.id === bboxId);
                if (bbox) {
                    // We can display the tooltip near the mouse pointer
                    // or some midpoint on the side. Let's use (mouseX, mouseY):
                    calculateStatsAndSetHoveredBBox(bbox, { x: mouseX, y: mouseY });
                }

                if (canvasRef.current && editingMode) {
                    // Same resizing cursor logic
                    if (side === "top" || side === "bottom") {
                        canvasRef.current.style.cursor = "ns-resize";
                    } else {
                        canvasRef.current.style.cursor = "ew-resize";
                    }
                }
            }
        } else {
            // Not hovering over corner or side
            setHoveredCorner(null);
            setHoveredSide(null);
            setHoveredBBox(null);
            if (canvasRef.current) {
                canvasRef.current.style.cursor = "default";
            }
        }
    };

    const finishNewBox = () => {
        const newVoxel = voxels.find((bbox) => bbox.id === newBoxId);
        if (newVoxel && (newVoxel.start.col === newVoxel.end.col || newVoxel.start.row === newVoxel.end.row)) {
            deleteSelectedVoxel();
        } else {
            persistVoxels();
        }
        setNewBoxId(null);
    };

    const finishResizing = () => {
        persistVoxels();
        setResizingState(null);
    };

    const handleMouseUp = (button: number) => {
        setCanvasMode("EMPTY");

        if (isAutoScollAnimationOn.current) {
            cancelAnimationFrame(isAutoScollAnimationOn.current);
            isAutoScollAnimationOn.current = null;
        }

        // Left mouse button (button === 0)
        if (button === 0) {
            // If in editing mode:
            if (editingMode) {
                // If we're currently resizing a box, finalize the resize
                if (resizingState) {
                    finishResizing(); // e.g. sets resizingState = null, persists to store
                }

                // If we've created a new box (indicated by newBoxId), finalize it
                if (newBoxId) {
                    finishNewBox(); // e.g. checks if box is valid and persists or deletes
                }
            } else {
                // Non-editing mode => ephemeral bounding box should vanish
                if (tempHighlightBox) {
                    setTempHighlightBox(null);
                }
            }
        }

        // Right mouse button (button === 2) => stop panning
        if (button === 2) {
            isPanning.current = false;
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.key === "z") {
            if (editingMode) {
                deselectVoxels();
                setResizingState(null);
                setNewBoxId(null);
                undo();
            } else {
                window.alert("Undo is only available in editing mode.");
            }
        }
        if (e.key === "Delete" && editingMode) {
            deleteSelectedVoxel();
            setResizingState(null);
            persistVoxels();
        }
    };

    const animate = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const posX = mousePosition.current.x;
        const posY = mousePosition.current.y;

        const verticalDirection = (posX < 0 ? 1 : posX > canvas.width ? -1 : 0) * AUTO_SCROLL_SPEED;
        const horizontalDirection = (posY < 0 ? 1 : posY > canvas.height ? -1 : 0) * AUTO_SCROLL_SPEED;

        const newOffsetX = offset.x + verticalDirection;
        const newOffsetY = offset.y + horizontalDirection;

        const scaledWidth = imageWidth * scale.x;
        const scaledHeight = imageHeight * scale.y;

        const clampedX = Math.max(Math.min(newOffsetX, 0), canvasSize.width - scaledWidth);
        const clampedY = Math.max(Math.min(newOffsetY, 0), canvasSize.height - scaledHeight);

        const mouseX = posX <= 0 ? 0 : Math.min(canvas.width - ALMOST_ZERO, posX);
        const mouseY = posY <= 0 ? 0 : Math.min(canvas.height - ALMOST_ZERO, posY);
        const offsetUpdated = { x: clampedX, y: clampedY };

        if (editingMode) {
            if (newBoxId) {
                updateNewBox(mouseX, mouseY, offsetUpdated);
            } else if (resizingState) {
                updateBoxStates(mouseX, mouseY, offsetUpdated);
            }
        } else if (tempHighlightBox) {
            updateTempHighlightBox(mouseX, mouseY, offsetUpdated);
        }

        onOffsetChange(offsetUpdated);
        isAutoScollAnimationOn.current = requestAnimationFrame(animate);
    };

    const isMouseLeftCanvas = (): boolean => {
        const canvas = canvasRef.current;
        if (!canvas) return false;

        return (
            mousePosition.current.x < 0 ||
            mousePosition.current.x > canvas.width ||
            mousePosition.current.y < 0 ||
            mousePosition.current.y > canvas.height
        );
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        const onMouseUp = (e: MouseEvent) => {
            handleMouseUp(e.button);
        };

        window.addEventListener("mouseup", onMouseUp);

        return () => {
            window.removeEventListener("mouseup", onMouseUp);
        };
    }, [handleMouseUp]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const mouseMove = (e: MouseEvent) => {
            const rect = canvas.getBoundingClientRect();
            mousePosition.current.x = e.clientX - rect.left;
            mousePosition.current.y = e.clientY - rect.top;

            if (
                !isPanning.current &&
                !isAutoScollAnimationOn.current &&
                canvasMode === "DRAWING" &&
                isMouseLeftCanvas()
            ) {
                isAutoScollAnimationOn.current = requestAnimationFrame(animate);
            } else if (isAutoScollAnimationOn.current && canvasMode === "DRAWING" && !isMouseLeftCanvas()) {
                cancelAnimationFrame(isAutoScollAnimationOn.current);
                isAutoScollAnimationOn.current = null;
            }
        };

        if (!isPanning.current && isAutoScollAnimationOn.current && canvasMode === "DRAWING" && isMouseLeftCanvas()) {
            cancelAnimationFrame(isAutoScollAnimationOn.current);
            isAutoScollAnimationOn.current = null;
            isAutoScollAnimationOn.current = requestAnimationFrame(animate);
        }

        window.addEventListener("mousemove", mouseMove);

        return () => {
            window.removeEventListener("mousemove", mouseMove);
        };
    }, [canvasMode, offset, editingMode, newBoxId, resizingState, tempHighlightBox, scale, heatmapData, voxels]);

    return (
        <div className="main-canvas-container" onContextMenu={(e) => e.preventDefault()}>
            <canvas
                ref={canvasRef}
                className="main-canvas"
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseDown}
                onWheel={(e) =>
                    handleWheel(e, scale, offset, canvasSize, imageWidth, imageHeight, onScaleChange, onOffsetChange)
                }
            />
            {hoveredBBox && (
                <div
                    style={{
                        left: hoveredBBox.position.x + 10,
                        top: hoveredBBox.position.y + 10
                    }}
                    className="hovered-bbox-tooltip"
                >
                    <div>Freq: {hoveredBBox.stats.freqRange}</div>
                    <div>Time: {hoveredBBox.stats.timeRange}</div>
                    <div>Power Max: {hoveredBBox.stats.max} dBmW</div>
                    <div>Power Avg: {hoveredBBox.stats.avg} dBmW</div>
                    <div>Power SUM: {hoveredBBox.stats.sum} dBmW</div>
                </div>
            )}
        </div>
    );
};
export default MainCanvas;
