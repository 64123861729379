import React, { useEffect } from "react";
import { useCacheStore } from "../utils/store";
import { calculateBoundingBoxStatistics } from "../utils/utils";
import "./SelectedVoxelInfo.css";

type VoxelStats = {
    freqRange: string;
    timeRange: string;
    max: string;
    avg: string;
    sum: string;
}


export const SelectedVoxelInfo = () => {
    const [selectedVoxelStats, setSelectedVoxelStats] = React.useState<VoxelStats | null>(null);
    const [info, setInfo] = React.useState<BoundingBox | null>(null);

    const editingMode = useCacheStore(state => state.editingMode);
    const voxels = useCacheStore(state => state.voxels);
    const tempHighlightBox = useCacheStore(state => state.tempHighlightBox);
    const heatmapData = useCacheStore(state => state.heatmapData);
    const selectedAntenna = useCacheStore(state => state.selectedAntenna);
    const selectedAggregation = useCacheStore(state => state.selectedAggregation);
    const updateVoxel = useCacheStore(state => state.updateVoxel);
    const persistVoxels = useCacheStore(state => state.persistVoxels);


    useEffect(() => {
        if (!heatmapData) return;

        if (tempHighlightBox) {
            setInfo(null);
            const stats = calculateBoundingBoxStatistics(
                tempHighlightBox.start,
                tempHighlightBox.end,
                tempHighlightBox.type,
                heatmapData,
                selectedAntenna,
                selectedAggregation
            );
            setSelectedVoxelStats(stats);
        } else {
            const selectedVoxel = voxels.find((v) => v.selected) ?? null;
            if (selectedVoxel) {
                setInfo(selectedVoxel);
                const stats = calculateBoundingBoxStatistics(
                    selectedVoxel.start,
                    selectedVoxel.end,
                    selectedVoxel.type,
                    heatmapData,
                    selectedAntenna,
                    selectedAggregation
                );
                setSelectedVoxelStats(stats);
            } else {
                setInfo(null);
                setSelectedVoxelStats(null);
            }
        }
    }, [editingMode, voxels, tempHighlightBox, heatmapData, selectedAntenna, selectedAggregation]);

    if (!selectedVoxelStats) return null;


    function updateText(event: React.ChangeEvent<HTMLInputElement>) {
        if (!info) return;
        updateVoxel({ ...info, comment: event.target.value });
        persistVoxels();
    }

    return (
        <div className="selected-voxel-info">
            {(info && !tempHighlightBox) &&
            <div>
                <h4>Selected Voxel Info</h4>
                <p>Modulation: {info.modulation}</p>
                <p>Emitter id: {info.emitter_id}</p>
                <p>Receiver power dbm: [{info.receive_power_dbm.join()}]</p>
                <p>Name: {info.name}</p>
                <p>Nr. subvoxels: {info.subvoxels?.length}</p>
                <p>Confidence: {info.confidence}</p>
                <p>
                    Comment:&nbsp;
                    {editingMode ? <input
                        className={"comment-input"}
                        value={info.comment || ""}
                        onChange={updateText}
                    /> : info.comment || ""}
                </p>
                <br />
            </div>
            }
            <h4>Selected Voxel Stats</h4>
            <p>Freq Range: {selectedVoxelStats.freqRange}</p>
            <p>Time Range: {selectedVoxelStats.timeRange}</p>
            <p>Power Max: {selectedVoxelStats.max} dBmW</p>
            <p>Power Avg: {selectedVoxelStats.avg} dBmW</p>
            <p>Power SUM: {selectedVoxelStats.sum} dBmW</p>
        </div>

    );
};
