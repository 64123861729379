// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useRef, useEffect } from "react";
import { useCacheStore } from "../utils/store";
import { hertz_divider, hertz_unit, HORIZONTAL_AXIS_HEIGHT } from "../utils/constants";

const VISIBILITY_SHIFT = 50;

const HorizontalAxis: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const heatmapData = useCacheStore(state => state.heatmapData);
    const visibleArea = useCacheStore(state => state.visibleArea);
    const canvasSize = useCacheStore(state => state.canvasSize);


    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        if (!heatmapData) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        if (canvas.width !== canvasSize.width) {
            canvas.width = VISIBILITY_SHIFT + canvasSize.width + VISIBILITY_SHIFT;
            canvas.height = HORIZONTAL_AXIS_HEIGHT;
        }

        ctx.clearRect(0, 0, canvasSize.width, HORIZONTAL_AXIS_HEIGHT);

        ctx.fillStyle = "#fff";
        ctx.strokeStyle = "#fff";
        ctx.font = "11px Arial";
        ctx.textBaseline = "top";
        ctx.textAlign = "left";

        ctx.fillText(hertz_unit, 0, 12);

        ctx.textAlign = "center";

        const totalTicks = Math.floor(canvas.width / 40);
        const visibleFreqRange =
            heatmapData.start_freq +
            (visibleArea.toCol / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq) -
            (heatmapData.start_freq +
                (visibleArea.fromCol / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq));

        // Quantize step size
        const tickStep = quantizeStep(visibleFreqRange / (totalTicks - 1));

        // Start at the visible area's start frequency, rounded to the nearest tick step
        const startValue =
            heatmapData.start_freq +
            (visibleArea.fromCol / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq);

        // Calculate and render ticks
        for (let i = 0; i < totalTicks; i++) {
            const tickValue = startValue + i * tickStep;

            const x = ((tickValue - startValue) * canvasSize.width) / visibleFreqRange + VISIBILITY_SHIFT;

            // Ensure X is within canvas bounds
            if (x >= VISIBILITY_SHIFT && x <= canvasSize.width + VISIBILITY_SHIFT) {
                // Draw tick line
                ctx.beginPath();
                ctx.moveTo(x, 0);
                ctx.lineTo(x, 10);
                ctx.stroke();

                // Draw tick label
                ctx.fillText(formatLabel(tickValue), x, 12);
            }
        }
    }, [visibleArea, canvasSize.width, heatmapData]);

    const quantizeStep = (step: number): number => {
        const STEP_DIVIDER = 100000;
        return Math.ceil(step / STEP_DIVIDER) * STEP_DIVIDER;
    };

    const formatLabel = (value: number): string => {
        return `${(value / hertz_divider).toFixed(1)} `;
    };

    return (
        <canvas
            ref={canvasRef}
            style={{
                border: "0px",
                height: `${HORIZONTAL_AXIS_HEIGHT}px`,
                width: `calc(100% + ${2 * VISIBILITY_SHIFT}px)`,
                marginLeft: `-${VISIBILITY_SHIFT}px`
            }}
        />
    );
};

export default HorizontalAxis;
