// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useEffect, useRef, useState } from "react";
import { useCacheStore } from "../utils/store";
import {
    normalizeData,
    createBluishImage,
    imageDataToBlob,
    calculateVisibleArea,
    calcMousePosToCanvas
} from "../utils/utils";
import "./Minimap.css";

const Minimap: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const heatmapData = useCacheStore(state => state.heatmapData);
    const canvasSize = useCacheStore(state => state.canvasSize);
    const visibleArea = useCacheStore(state => state.visibleArea);
    const setVisibleArea = useCacheStore(state => state.setVisibleArea);
    const selectedAntenna = useCacheStore(state => state.selectedAntenna);
    const selectedAggregation = useCacheStore(state => state.selectedAggregation);
    const minDb = useCacheStore(state => state.minDb);
    const maxDb = useCacheStore(state => state.maxDb);
    const scale = useCacheStore(state => state.scale);
    const offset = useCacheStore(state => state.offset);
    const setOffset = useCacheStore(state => state.setOffset);


    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState<{ x: number; y: number } | null>(null);

    const handleMinimapMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!canvasRef.current || !heatmapData || !visibleArea) return;


        const ctx = canvasRef.current.getContext("2d");
        if (!ctx) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const {
            mouseX,
            mouseY,
            scaleX,
            scaleY
        } = calcMousePosToCanvas(e.clientX, e.clientY, rect, canvasRef.current.width, canvasRef.current.height, heatmapData);

        const { fromCol, fromRow, toCol, toRow } = visibleArea;
        const visibleX = fromCol * scaleX;
        const visibleY = fromRow * scaleY;
        const visibleW = (toCol - fromCol) * scaleX;
        const visibleH = (toRow - fromRow) * scaleY;

        // Check if click is inside visible rectangle
        if (
            mouseX >= visibleX &&
            mouseX <= visibleX + visibleW &&
            mouseY >= visibleY &&
            mouseY <= visibleY + visibleH
        ) {
            setIsDragging(true);
            // Compute center of visible rectangle
            const centerX = visibleX + visibleW / 2;
            const centerY = visibleY + visibleH / 2;

            // Store offset from rectangle center to cursor
            setDragOffset({ x: mouseX - centerX, y: mouseY - centerY });
        }
    };

    const handleMinimapMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (
            !isDragging ||
            !canvasRef.current ||
            !heatmapData ||
            !scale ||
            !canvasSize ||
            !dragOffset ||
            !visibleArea
        ) return;

        // Get canvas bounding rect and compute accurate mouse coordinates within canvas
        const rect = canvasRef.current.getBoundingClientRect();
        const {
            mouseX,
            mouseY,
            scaleX,
            scaleY
        } = calcMousePosToCanvas(e.clientX, e.clientY, rect, canvasRef.current.width, canvasRef.current.height, heatmapData);

        // Adjust cursor position using the initial offset from the rectangle's center
        const adjustedCenterX = mouseX - dragOffset.x;
        const adjustedCenterY = mouseY - dragOffset.y;

        // Derive visible rectangle dimensions from the current visibleArea
        const { fromCol, fromRow, toCol, toRow } = visibleArea;
        const visibleW = (toCol - fromCol) * scaleX;
        const visibleH = (toRow - fromRow) * scaleY;

        // Calculate the top-left position of the visible rectangle based on the adjusted center
        let newVisibleX = adjustedCenterX - visibleW / 2;
        let newVisibleY = adjustedCenterY - visibleH / 2;

        // Clamp the visible rectangle's top-left so it doesn't exit the canvas boundaries
        newVisibleX = Math.max(0, Math.min(newVisibleX, canvasRef.current.width - visibleW));
        newVisibleY = Math.max(0, Math.min(newVisibleY, canvasRef.current.height - visibleH));

        // Compute the clamped center of the visible rectangle
        const clampedCenterX = newVisibleX + visibleW / 2;
        const clampedCenterY = newVisibleY + visibleH / 2;

        // Convert the clamped center to image-space coordinates
        const imageX = clampedCenterX / scaleX;
        const imageY = clampedCenterY / scaleY;

        // Calculate new offsets so the main canvas centers at the clamped center
        const newOffsetX = canvasSize.width / 2 - imageX * scale.x;
        const newOffsetY = canvasSize.height / 2 - imageY * scale.y;

        setOffset({ x: newOffsetX, y: newOffsetY });
    };

    const handleMinimapMouseUp = () => {
        setIsDragging(false);
        setDragOffset(null);
    };

    useEffect(() => {
        if (!canvasRef.current || !heatmapData || heatmapData.width == null || heatmapData.height == null) return;

        let isMounted = true;

        // Obtain the image data
        const imageData = heatmapData.images?.[selectedAntenna]?.[selectedAggregation];

        if (!imageData) {
            console.error("Image data is not available.");
            return;
        }

        // Capture width and height in constants
        const width = heatmapData.width;
        const height = heatmapData.height;

        // Process the image data to create an image
        async function processAndCreateImage() {
            const normalized = normalizeData(imageData!, minDb, maxDb);
            const bluish = createBluishImage(normalized, width, height);
            const blob = await imageDataToBlob(bluish);
            const blobUrl = URL.createObjectURL(blob);

            const img = new Image();
            img.src = blobUrl;
            img.onload = () => {
                if (isMounted) {
                    setImage(img);
                }
                URL.revokeObjectURL(blobUrl);
            };
        }

        processAndCreateImage().catch((error) => {
            console.error("Error processing image data for minimap:", error);
        });

        return () => {
            isMounted = false;
        };
    }, [heatmapData, selectedAntenna, selectedAggregation]);

    useEffect(() => {
        if (!canvasRef.current || !heatmapData || !image) return;

        const canvas = canvasRef.current;
        const parent = canvas.parentElement;
        if (!parent) return;

        // Set canvas dimensions
        canvas.width = parent.clientWidth;

        const aspectRatio = heatmapData.height / heatmapData.width;
        canvas.height = canvas.width * aspectRatio;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw the heatmap image scaled to the canvas size
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        const scaleX = canvas.width / heatmapData.width;
        const scaleY = canvas.height / heatmapData.height;

        const { fromCol, toCol, fromRow, toRow } = visibleArea;

        // Calculate the visible area rectangle
        const visibleX = fromCol * scaleX;
        const visibleY = fromRow * scaleY;
        const visibleWidth = (toCol - fromCol) * scaleX;
        const visibleHeight = (toRow - fromRow) * scaleY;

        // Draw the visible area rectangle
        ctx.strokeStyle = "#ffffff";
        ctx.lineWidth = 2;
        ctx.strokeRect(visibleX, visibleY, visibleWidth, visibleHeight);
    }, [heatmapData, visibleArea, image, offset]);

    useEffect(() => {
        if (!image || !heatmapData) return;

        const {
            visibleFromX,
            visibleToX,
            visibleFromY,
            visibleToY
        } = calculateVisibleArea(image.width, image.height, scale, offset, canvasSize);

        setVisibleArea({
            fromRow: Math.floor(visibleFromY),
            toRow: Math.ceil(visibleToY),
            fromCol: Math.floor(visibleFromX),
            toCol: Math.ceil(visibleToX)
        });
    }, [offset, scale, image, heatmapData]);

    return (
        <>
            {heatmapData && (
                <canvas
                    ref={canvasRef}
                    className="minimap-canvas"
                    aria-label="Minimap showing the heatmap with the current view area highlighted"
                    onMouseDown={handleMinimapMouseDown}
                    onMouseMove={handleMinimapMouseMove}
                    onMouseUp={handleMinimapMouseUp}
                    onMouseLeave={handleMinimapMouseUp}
                />
            )}
        </>
    );
};

export default Minimap;
