// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useEffect, useRef, useState } from "react";
import { LONG_VOXEL_PANEL_HEIGHT, DEFAULT_VOXEL_INFO, MAIN_CANVAS_VERTICAL_AXIS_SIZE, purple } from "../utils/constants";
import { useCacheStore } from "../utils/store";

interface LongVoxelPanelProps {
    numBins: number;
    imageWidth: number;
}

const LongVoxelPanel: React.FC<LongVoxelPanelProps> = ({ numBins, imageWidth }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isSelecting, setIsSelecting] = useState<{ id: number; startIdx: number; endIdx: number } | null>(null);
    const [visibleStartBin, setVisibleStartBin] = useState(0);
    const [visibleEndBin, setVisibleEndBin] = useState(numBins - 1);
    const scale = useCacheStore(state => state.scale);
    const offset = useCacheStore(state => state.offset);
    const voxels = useCacheStore(state => state.voxels);
    const getNextBoxId = useCacheStore(state => state.getNextBoxId);
    const deselectVoxels = useCacheStore(state => state.deselectVoxels);
    const showVoxels = useCacheStore(state => state.showVoxels);
    const updateVoxel = useCacheStore(state => state.updateVoxel);
    const heatmapData = useCacheStore(state => state.heatmapData);
    const persistVoxels = useCacheStore(state => state.persistVoxels);
    const editingMode = useCacheStore(state => state.editingMode);
    const canvasSize = useCacheStore(state => state.canvasSize);
    const tempHighlightBox = useCacheStore(state => state.tempHighlightBox);
    const setTempHighlightBox = useCacheStore(state => state.setTempHighlightBox);
    const canvasMode = useCacheStore(state => state.canvasMode);


    const isDrawingCanvas = canvasMode === "DRAWING";

    useEffect(() => {
        if (canvasRef.current) {
            canvasRef.current.width = canvasSize.width;
            canvasRef.current.height = LONG_VOXEL_PANEL_HEIGHT;
        }
        drawBins();
    }, [numBins, voxels, scale, offset, imageWidth, canvasSize.width, isSelecting, tempHighlightBox]);

    const drawBins = () => {
        if (!canvasRef.current) return;
        const ctx = canvasRef.current.getContext("2d");
        if (!ctx) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const displayedWidth = rect.width;
        const displayedHeight = rect.height;

        ctx.clearRect(0, 0, displayedWidth, displayedHeight);

        // Compute the visible bin range from MainCanvas
        const totalImageWidth = imageWidth * scale.x;
        const visibleStartX = -offset.x;
        const visibleEndX = -offset.x + canvasSize.width;

        const fractionStart = visibleStartX / totalImageWidth;
        const fractionEnd = visibleEndX / totalImageWidth;

        const computedVisibleStartBin = Math.max(0, Math.floor(fractionStart * numBins + 1));
        const computedVisibleEndBin = Math.min(numBins, Math.floor(fractionEnd * numBins - 1));

        setVisibleStartBin(computedVisibleStartBin);
        setVisibleEndBin(computedVisibleEndBin);

        const numVisibleBins = computedVisibleEndBin - computedVisibleStartBin + 1;
        const binWidth = displayedWidth / numVisibleBins;

        // Draw bins
        for (let i = computedVisibleStartBin; i <= computedVisibleEndBin; i++) {
            const x = (i - computedVisibleStartBin) * binWidth;

            let isSelected = false;
            if (showVoxels) {
                // Check if this bin is within any of the selected intervals
                voxels.forEach(({ start, end, type }) => {
                    if (type !== "longVoxel") return;
                    if (i >= start.col && i <= end.col) {
                        isSelected = true;
                    }
                });
                // Also check if it's being currently selected
                if (isSelecting) {
                    const minBin = Math.min(isSelecting.startIdx, isSelecting.endIdx);
                    const maxBin = Math.max(isSelecting.startIdx, isSelecting.endIdx);
                    if (i >= minBin && i <= maxBin) {
                        isSelected = true;
                    }
                }

                // Mark bin if it belongs to the ephemeral tempHighlightBox (if it's a longVoxel)
                if (!editingMode && tempHighlightBox && tempHighlightBox.type === "longVoxel") {
                    const minBin = Math.min(tempHighlightBox.start.col, tempHighlightBox.end.col);
                    const maxBin = Math.max(tempHighlightBox.start.col, tempHighlightBox.end.col);
                    if (i >= minBin && i <= maxBin) {
                        isSelected = true;
                    }
                }
            }
            
            ctx.fillStyle = isSelected ? purple : "grey";
            ctx.fillRect(x, 0, binWidth + 1, displayedHeight);
        }
    };

    const getBinIndex = (mouseX: number) => {
        if (!canvasRef.current) return -1;

        const rect = canvasRef.current.getBoundingClientRect();
        const displayedWidth = rect.width;
        const numVisibleBins = visibleEndBin - visibleStartBin + 1;
        const binWidth = displayedWidth / numVisibleBins;
        const binOffset = Math.floor(mouseX / binWidth);
        return visibleStartBin + binOffset;
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!showVoxels || isDrawingCanvas) return; // If we don't show voxels, skip
        const rect = canvasRef.current!.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const binIndex = getBinIndex(mouseX);
        if (binIndex < 0 || binIndex >= numBins) return;

        // Deselect old boxes
        deselectVoxels();

        if (editingMode) {
            // Editing: create a real longVoxel in store
            setIsSelecting({ id: getNextBoxId(), startIdx: binIndex, endIdx: binIndex });
        } else {
            // Non-editing: create ephemeral highlight box in store
            setTempHighlightBox({
                ...DEFAULT_VOXEL_INFO,
                id: -1,
                start: { row: 0, col: binIndex },
                end: { row: heatmapData!.height - 1, col: binIndex },
                type: "longVoxel",
                drawing: "continuous",
                selected: true
            });
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!showVoxels || isDrawingCanvas) return;
        if (!canvasRef.current) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const binIndex = getBinIndex(mouseX);

        // Editing mode => real store-based voxel
        if (editingMode && isSelecting) {
            if (binIndex >= 0 && binIndex < numBins && binIndex !== isSelecting.endIdx) {
                setIsSelecting({ ...isSelecting, endIdx: binIndex });
                updateVoxel({
                    ...DEFAULT_VOXEL_INFO,
                    id: isSelecting.id,
                    start: {
                        row: 0,
                        col: Math.min(isSelecting.startIdx, binIndex)
                    },
                    end: {
                        row: heatmapData!.height - 1,
                        col: Math.max(isSelecting.startIdx, binIndex)
                    },
                    type: "longVoxel",
                    drawing: "continuous",
                    selected: true
                });
            }
        }
        // Non-editing => ephemeral highlight
        else if (!editingMode && tempHighlightBox) {
            if (binIndex >= 0 && binIndex < numBins) {
                setTempHighlightBox({
                    ...tempHighlightBox,
                    start: {
                        row: 0,
                        col: Math.min(tempHighlightBox.start.col, binIndex)
                    },
                    end: {
                        row: heatmapData!.height - 1,
                        col: Math.max(tempHighlightBox.start.col, binIndex)
                    }
                });
            }
        }
    };

    const handleMouseStop = () => {
        if (!showVoxels || isDrawingCanvas) return;

        // If editing, finalize store-based voxel
        if (editingMode && isSelecting) {
            setIsSelecting(null);
            persistVoxels();
        }
        // If non-editing, remove ephemeral highlight
        else if (!editingMode && tempHighlightBox) {
            setTempHighlightBox(null);
        }
    };

    return (
        <canvas
            ref={canvasRef}
            className="longvoxel-panel"
            style={{
                width: `calc(100% - ${MAIN_CANVAS_VERTICAL_AXIS_SIZE}px)`,
                height: `${LONG_VOXEL_PANEL_HEIGHT}px`,
                marginLeft: `${MAIN_CANVAS_VERTICAL_AXIS_SIZE}px`
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseStop}
            onMouseLeave={handleMouseStop}
        />
    );
};

export default LongVoxelPanel;
