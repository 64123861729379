// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useEffect, useState } from "react";
import "./ControlPanel.css";
import { fetchMatrix, fetchRestMatrix, voxelTransformator } from "../utils/utils";
import { useCacheStore } from "../utils/store";
import Toggle from "./Toggle";
import Minimap from "./Minimap";
import TreeView, { TreeNode } from "./TreeView";
import { API_URL, version } from "../utils/constants";
import GradientSlider from "./GradientSlider";
import { InfoMenu } from "./menu/InfoMenu";

const ControlPanel: React.FC = () => {
    const selectedAntenna = useCacheStore(state => state.selectedAntenna);
    const setSelectedAntenna = useCacheStore(state => state.setSelectedAntenna);
    const selectedAggregation = useCacheStore(state => state.selectedAggregation);
    const setAggregation = useCacheStore(state => state.setAggregation);
    const setError = useCacheStore(state => state.setError);
    const setLoading = useCacheStore(state => state.setLoading);
    const heatmapData = useCacheStore(state => state.heatmapData);
    const setHeatmapData = useCacheStore(state => state.setHeatmapData);
    const initVoxels = useCacheStore(state => state.initVoxels);
    const getNextBoxId = useCacheStore(state => state.getNextBoxId);
    const reset = useCacheStore(state => state.reset);
    const selectedPath = useCacheStore(state => state.selectedPath);
    const setSelectedPath = useCacheStore(state => state.setSelectedPath);
    const loading = useCacheStore(state => state.loading);
    const updateHeatmapData = useCacheStore(state => state.updateHeatmapData);
    const setMinDb = useCacheStore(state => state.setMinDb);
    const setMaxDb = useCacheStore(state => state.setMaxDb);
    const minPaprDb = useCacheStore(state => state.minPaprDb);
    const maxPaprDb = useCacheStore(state => state.maxPaprDb);
    const showVoxels = useCacheStore(state => state.showVoxels);
    const setShowVoxels = useCacheStore(state => state.setShowVoxels);
    const editingMode = useCacheStore(state => state.editingMode);
    const setEditingMode = useCacheStore(state => state.setEditingMode);
    const setMeasurements = useCacheStore(state => state.setMeasurements);
    const toolMode = useCacheStore(state => state.toolMode);
    const voxelShading = useCacheStore(state => state.voxelOpacity);
    const setVoxelShading = useCacheStore(state => state.setVoxelOpacity);


    const [activePage, setActivePage] = useState<"data" | "palette">("data");
    const [isTreeViewOpen, setIsTreeViewOpen] = useState<boolean>(true);
    const [files, setFiles] = useState<TreeNode[]>([]);
    const [savedEditingMode, setSavedEditingMode] = useState(false);

    useEffect(() => {
        if (!showVoxels) {
            // 1) We're turning "Show Voxels" off
            //    Save the current editingMode, then force editingMode off
            setSavedEditingMode(editingMode);
            if (editingMode) {
                setEditingMode(false);
            }
        } else {
            // 2) We're turning "Show Voxels" on
            //    Restore editingMode from savedEditingMode
            //    so that it "remembers" the last user setting
            setEditingMode(savedEditingMode);
        }
    }, [showVoxels]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        if (!selectedPath) {
            setHeatmapData(null);
            setLoading(false);
            setError("Please choose a file from the tree view");
            return;
        }

        setLoading(true);
        setError("");
        setHeatmapData(null);

        fetchMatrix(selectedPath, signal)
            .then(({ voxels, ...data }: ResponseHeatmapData) => {
                setHeatmapData(data);
                initVoxels(voxelTransformator(voxels, data.height, getNextBoxId));
                setMeasurements(data.measurements);
                setMinDb(data.minValue);
                setMaxDb(data.maxValue);
                setError("");
                setLoading(false);

                fetchRestMatrix(
                    selectedPath,
                    Object.keys(data.images).length,
                    Object.keys(data.images["0"]),
                    updateHeatmapData,
                    data.width,
                    data.height,
                    signal
                ).catch((error) => {
                    console.log("Error fetching rest matrix:", error);
                });
            })
            .catch((error) => {
                setError(error.message || "Error fetching data");
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            controller.abort();
        };
    }, [selectedPath]);

    useEffect(() => {
        fetch(`${API_URL}/spectrums`, { credentials: "include" })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to fetch spectrum data");
                }
            })
            .then((data: TaggingFiles[]) => {
                const transformedData = data.map(
                    (tf, index) =>
                        ({
                            id: index.toString(),
                            name: tf.path,
                            type: "file",
                            status: tf.status
                        }) as TreeNode
                );
                setFiles(transformedData);
            })
            .catch((error) => {
                console.error("Error fetching spectrum data:", error);
            });
    }, [isTreeViewOpen]);

    const handleButtonClick = (index: number, aggregation: string) => {
        setSelectedAntenna(index);
        setAggregation(aggregation);
        if (aggregation === "papr") {
            setMinDb(minPaprDb);
            setMaxDb(maxPaprDb);
        } else {
            const mindBValue = heatmapData?.minValue ?? -120;
            const maxdBValue = heatmapData?.maxValue ?? 0;
            setMinDb(mindBValue);
            setMaxDb(maxdBValue);
        }
    };

    const handleResetDb = (aggregation: string) => {
        if (aggregation === "papr") {
            setMinDb(minPaprDb);
            setMaxDb(maxPaprDb);
        } else {
            const mindBValue = heatmapData?.minValue ?? -120;
            const maxdBValue = heatmapData?.maxValue ?? 0;
            setMinDb(mindBValue);
            setMaxDb(maxdBValue);
        }
    };

    const toggleTreeView = () => {
        setIsTreeViewOpen(!isTreeViewOpen);
    };

    const handleFileSelect = (filePath: string) => {
        toggleTreeView();
        if (selectedPath === filePath) {
            return;
        }
        reset();
        setSelectedPath(filePath);
    };

    const handleOverlayClick = () => {
        if (isTreeViewOpen) {
            setIsTreeViewOpen(false);
        }
    };

    useEffect(() => {
        if (isTreeViewOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isTreeViewOpen]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape" && isTreeViewOpen) {
                setIsTreeViewOpen(false);
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isTreeViewOpen, loading]);

    return (
        <div className="control-panel-container">
            {/* Hamburger menu */}
            <div className="hamburger-menu-wrapper">
                <div
                    className={`hamburger-menu-button ${isTreeViewOpen ? "active" : ""}`}
                    onClick={toggleTreeView}
                    aria-label="Toggle File Explorer"
                    tabIndex={0}
                    onKeyUp={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                            toggleTreeView();
                        }
                    }}
                >
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>

            <div className="side-icons">
                {/* Data Tagger Icon */}
                <button
                    className={`tab-button ${activePage === "data" ? "active" : ""}`}
                    onClick={() => setActivePage("data")}
                    title="Info"
                    style={{ padding: "5px" }}
                >
                    Info
                </button>

                <button
                    className={`tab-button ${activePage === "palette" ? "active" : ""}`}
                    onClick={() => setActivePage("palette")}
                    title="Color Palette"
                    style={{ padding: "5px" }}
                >
                    Edit
                </button>
            </div>

            {/* Container for side panel + main content */}
            <div className={`control-panel-inside-container ${isTreeViewOpen ? "open" : ""}`}>
                {/* Side panel */}
                <div className={`side-panel ${isTreeViewOpen ? "open" : ""}`}>
                    {/* Recording Explorer */}
                    <p className="side-panel-title">Recording Explorer</p>
                    <TreeView data={files} onFileSelect={handleFileSelect} />
                </div>

                {/* Main content area */}
                <div className="control-panel-inside">
                    {activePage === "data" && <InfoMenu />}

                    {activePage === "palette" && (
                        <>
                            <h2>PSD Tagger v{version}</h2>
                            {heatmapData && (
                                <>
                                    <div className="scrollable-table-container">
                                        <table className="button-table">
                                            <thead>
                                                <tr>
                                                    <th>Receivers</th>
                                                    <th>avg</th>
                                                    <th>max</th>
                                                    <th>papr</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(heatmapData.images).map((index) => (
                                                    <tr key={index}>
                                                        <td>{heatmapData.receiver_infos ? `${heatmapData.receiver_infos[Number(index)].node_id} ${heatmapData.receiver_infos[Number(index)].radio_alias}` : index}</td>
                                                        {["avg", "max", "papr"].map((feature) => (
                                                            <td key={feature}>
                                                                <button
                                                                    disabled={!heatmapData.images[index][feature]}
                                                                    onClick={() =>
                                                                        handleButtonClick(parseInt(index, 10), feature)
                                                                    }
                                                                    className={`button ${
                                                                        selectedAntenna === parseInt(index, 10) &&
                                                                    selectedAggregation === feature
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    {feature}
                                                                </button>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <GradientSlider
                                        start={
                                            selectedAggregation === "papr"
                                                ? minPaprDb - 2
                                                : heatmapData.minValue - 2
                                        }
                                        end={
                                            selectedAggregation === "papr"
                                                ? maxPaprDb + 2
                                                : heatmapData.maxValue + 2
                                        }
                                    />

                                    <button onClick={() => handleResetDb(selectedAggregation)}>
                                        Reset Color Scale
                                    </button>

                                    <div className="toggles">
                                        <div className="toggle-item">
                                            <span>Show voxels</span>
                                            <Toggle 
                                                state={showVoxels} 
                                                text=""
                                                setState={setShowVoxels} 
                                            />
                                        </div>

                                        <div className="toggle-item">
                                            <span>Edit mode</span>
                                            <Toggle 
                                                state={editingMode} 
                                                text=""
                                                setState={(value) => {
                                                    if(toolMode === "COMP"){
                                                        window.alert("Editing not supported during comparison mode");
                                                        return;
                                                    }
                                                    setEditingMode(value);
                                                }} 
                                            />
                                        </div>

                                        <div className="toggle-item">
                                            <span>Voxel Shading</span>
                                            <Toggle 
                                                state={voxelShading} 
                                                text=""
                                                setState={setVoxelShading} 
                                            />
                                        </div>
                                    </div>
                                    <h3 className="minimap-title">Minimap</h3>
                                    <div className="minimap-container">
                                        <Minimap />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>

            {isTreeViewOpen && <div className="overlay" onClick={handleOverlayClick}></div>}
        </div>
    );
};

export default ControlPanel;
