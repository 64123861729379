// Copyright (C) 2024 Xtremis, All rights reserved

import React, { useRef, useEffect, useState } from "react";
import {
    hertz_divider,
    hertz_unit,
    PSD_HEIGHT,
    MIN_BIN_WIDTH,
    MAIN_CANVAS_VERTICAL_AXIS_SIZE,
    MAIN_CANVAS_HORIZONTAL_AXIS_SIZE
} from "../utils/constants";
import { useCacheStore } from "../utils/store";
import { getColorFromNormalizedValue } from "../utils/utils";
import "./PSDDiagram.css";
import { drawVerticalLine } from "../utils/drawutils";

const PSDDiagram: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [avgData, setAvgData] = useState<number[]>([]);
    const [maxData, setMaxData] = useState<number[]>([]);

    const heatmapData = useCacheStore(state => state.heatmapData);
    const selectedAntenna = useCacheStore(state => state.selectedAntenna);
    const selectedAggregation = useCacheStore(state => state.selectedAggregation);
    const visibleArea = useCacheStore(state => state.visibleArea);
    const hoverCoords = useCacheStore(state => state.hoverCoords);
    const canvasSize = useCacheStore(state => state.canvasSize);
    const minDb = useCacheStore(state => state.minDb);
    const maxDb = useCacheStore(state => state.maxDb);
    const minPaprDb = useCacheStore(state => state.minPaprDb);
    const maxPaprDb = useCacheStore(state => state.maxPaprDb);
    const offset = useCacheStore(state => state.offset);
    const scale = useCacheStore(state => state.scale);
    const voxels = useCacheStore(state => state.voxels);
    const isSelectingLongVoxel = useCacheStore(state => state.isSelectingLongVoxel);
    const tempHighlightBox  = useCacheStore(state => state.tempHighlightBox);
    const editingMode  = useCacheStore(state => state.editingMode);



    const [width, setWidth] = useState<number>(0);
    const [hoverCoordinates, setHoverCoordinates] = useState(hoverCoords);

    useEffect(() => {
        setHoverCoordinates({ x: Math.floor(hoverCoords.x), y: Math.floor(hoverCoords.y) });
    }, [hoverCoords]);

    useEffect(() => {
        if (!heatmapData) return;
        setAvgData(
            Array.from(
                heatmapData.images[selectedAntenna]["avg"]!.slice(
                    hoverCoordinates.y * heatmapData.width + visibleArea.fromCol,
                    hoverCoordinates.y * heatmapData.width + visibleArea.toCol
                )
            )
        );
        if (heatmapData.images[selectedAntenna]["max"]) {
            setMaxData(
                Array.from(
                    heatmapData.images[selectedAntenna]["max"]!.slice(
                        hoverCoordinates.y * heatmapData.width + visibleArea.fromCol,
                        hoverCoordinates.y * heatmapData.width + visibleArea.toCol
                    )
                )
            );
        } else {
            setMaxData([]);
        }
    }, [heatmapData, selectedAggregation, selectedAntenna, visibleArea, hoverCoordinates]);

    const aggregateData = (data: number[], drawableWidth: number): number[] => {
        const maxBins = Math.floor(drawableWidth / MIN_BIN_WIDTH);
        if (data.length <= maxBins) {
            return data; // No need to aggregate if data fits within the bins
        }

        const aggregatedData: number[] = [];
        const groupSize = Math.ceil(data.length / maxBins);

        for (let i = 0; i < data.length; i += groupSize) {
            const group = data.slice(i, i + groupSize);
            aggregatedData.push(Math.max(...group));
        }

        return aggregatedData;
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        if (canvas.width !== canvasSize.width + MAIN_CANVAS_VERTICAL_AXIS_SIZE) {
            canvas.width = canvasSize.width + MAIN_CANVAS_VERTICAL_AXIS_SIZE;
            canvas.height = PSD_HEIGHT;
            setWidth(canvasSize.width + MAIN_CANVAS_VERTICAL_AXIS_SIZE);
        }

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        ctx.clearRect(0, 0, width, PSD_HEIGHT);

        if (!heatmapData) return;

        const axisColor = "#cccccc";
        const gridColor = "#444444";
        const avgCurveColor = "#4caf50";
        const avgFillColor = "rgba(76, 175, 80, 0.4)";
        const maxCurveColor = "#93b322";
        const labelColor = "#ffffff";

        // Draw axis lines
        ctx.strokeStyle = axisColor;
        ctx.lineWidth = 2;

        ctx.beginPath();
        ctx.moveTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, 0);
        ctx.lineTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE);
        ctx.lineTo(width, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE);
        ctx.stroke();

        const drawableWidth = width - MAIN_CANVAS_VERTICAL_AXIS_SIZE;
        const drawableHeight = PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE - 5;

        const colorBarWidth = 5; // Thin color bar
        const colorBarHeight = drawableHeight;
        // Place it just left of the main axis (padding)
        const colorBarX = MAIN_CANVAS_VERTICAL_AXIS_SIZE - colorBarWidth;
        const colorBarY = PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE - colorBarHeight;

        // Loop through each row from bottom => top
        for (let i = 0; i < colorBarHeight; i++) {
            // fraction in [0..1]
            const fraction = i / (colorBarHeight - 1);

            let dBVal: number;

            // Convert that fraction to an actual dB value in [minDb..maxDb].
            // i=0 => dBVal = minDb, i=colorBarHeight-1 => dBVal = maxDb.
            if (selectedAggregation === "papr") {
                dBVal = minPaprDb + fraction * (maxPaprDb - minPaprDb);
            } else {
                dBVal = heatmapData.minValue + fraction * (heatmapData.maxValue - heatmapData.minValue);
            }

            const norm = (dBVal - minDb) / (maxDb - minDb);
            // clamp just in case
            const clamped = Math.max(0, Math.min(1, norm));

            const { r, g, b } = getColorFromNormalizedValue(clamped);
            const rowY = colorBarY + (colorBarHeight - 1 - i);

            ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
            ctx.fillRect(colorBarX, rowY, colorBarWidth, 1);
        }

        ctx.fillStyle = labelColor;
        ctx.font = "12px Arial";
        ctx.textAlign = "right";
        ctx.textBaseline = "top";
        ctx.fillText(
            hertz_unit,
            MAIN_CANVAS_VERTICAL_AXIS_SIZE - 20,
            PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE + 10
        );

        const aggregatedAvgData = aggregateData(avgData, drawableWidth);
        const aggregatedMaxData = aggregateData(maxData, drawableWidth);

        ctx.textBaseline = "middle";

        // Vertical ticks
        const numVerticalTicks = 10;
        for (let i = 0; i < numVerticalTicks - 1; i++) {
            const tickValue =
                heatmapData.minValue + (i * (heatmapData.maxValue - heatmapData.minValue)) / (numVerticalTicks - 1);
            const y = PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE - (i * drawableHeight) / (numVerticalTicks - 1);

            ctx.strokeStyle = gridColor;
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, y);
            ctx.lineTo(width, y);
            ctx.stroke();

            // Thick tick
            ctx.strokeStyle = labelColor;
            ctx.lineWidth = 4;
            ctx.beginPath();
            ctx.moveTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, y);
            ctx.lineTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE - 5, y);
            ctx.stroke();

            ctx.fillText(tickValue.toFixed(1), MAIN_CANVAS_VERTICAL_AXIS_SIZE - 10, y);
        }

        // Add dB label at top-left of vertical axis
        ctx.fillStyle = labelColor;
        ctx.font = "12px Arial";
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        ctx.fillText("(dBmW)", MAIN_CANVAS_VERTICAL_AXIS_SIZE - 31, MAIN_CANVAS_HORIZONTAL_AXIS_SIZE - 40);

        // Horizontal ticks
        const numHorizontalTicks = Math.trunc((width + MAIN_CANVAS_VERTICAL_AXIS_SIZE) / 60);
        ctx.font = "12px Arial";
        ctx.textAlign = "center";
        ctx.textBaseline = "top";

        for (let i = 0; i < numHorizontalTicks; i++) {
            const colValue =
                visibleArea.fromCol + (i * (visibleArea.toCol - visibleArea.fromCol)) / (numHorizontalTicks - 1);

            const tickValue =
                heatmapData.start_freq +
                (colValue / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq);

            const x = MAIN_CANVAS_VERTICAL_AXIS_SIZE + (i * drawableWidth) / (numHorizontalTicks - 1);

            ctx.strokeStyle = gridColor;
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE);
            ctx.stroke();

            // Thick tick
            ctx.strokeStyle = labelColor;
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(x, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE);
            ctx.lineTo(x, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE + 5);
            ctx.stroke();

            ctx.fillStyle = labelColor;
            if (i === numHorizontalTicks - 1) ctx.textAlign = "right";
            ctx.fillText((tickValue / hertz_divider).toFixed(1), x, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE + 10);
        }

        const binWidth = drawableWidth / aggregatedAvgData.length;
        ctx.strokeStyle = avgCurveColor;
        aggregatedAvgData.forEach((value, index) => {
            const x = MAIN_CANVAS_VERTICAL_AXIS_SIZE + index * binWidth + binWidth / 2;
            const binHeight =
                ((value - heatmapData.minValue) / (heatmapData.maxValue - heatmapData.minValue)) * drawableHeight;
            const clampedBinHeight = Math.max(0, binHeight);

            if (index === 0) {
                ctx.moveTo(x, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE - clampedBinHeight);
            } else {
                ctx.lineTo(x, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE - clampedBinHeight);
            }
        });
        ctx.lineTo(
            MAIN_CANVAS_VERTICAL_AXIS_SIZE + (aggregatedAvgData.length - 1) * binWidth,
            PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE
        );
        ctx.stroke();
        ctx.fillStyle = avgFillColor;
        ctx.lineTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE);
        ctx.closePath();
        ctx.fill();

        // Draw maxData line
        ctx.strokeStyle = maxCurveColor;
        ctx.lineWidth = 2;
        ctx.beginPath();
        aggregatedMaxData.forEach((value, index) => {
            const x = MAIN_CANVAS_VERTICAL_AXIS_SIZE + index * binWidth + binWidth / 2;
            const y =
                PSD_HEIGHT -
                MAIN_CANVAS_HORIZONTAL_AXIS_SIZE -
                ((value - heatmapData.minValue) / (heatmapData.maxValue - heatmapData.minValue)) * drawableHeight;

            if (index === 0) {
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
            }
        });
        ctx.stroke();

        // If hoverCoordinates is within bounds, compute and show the time in the top-right corner
        if (
            hoverCoordinates.y >= 0 &&
            hoverCoordinates.y < heatmapData.height &&
            hoverCoordinates.x >= 0 &&
            hoverCoordinates.x < heatmapData.width
        ) {
            const time =
                heatmapData.end_time -
                (hoverCoordinates.y / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time);
            const freq =
                heatmapData.start_freq +
                (hoverCoordinates.x / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq);
            ctx.fillStyle = labelColor;
            ctx.font = "15px Arial";
            ctx.textAlign = "center";
            ctx.textBaseline = "top";
            ctx.fillText(
                "Cross: " + time.toFixed(1) + " s, " + (freq / hertz_divider).toFixed(1) + " " + hertz_unit,
                MAIN_CANVAS_VERTICAL_AXIS_SIZE + (width - MAIN_CANVAS_VERTICAL_AXIS_SIZE) / 2,
                10
            );
        }
        
        let hoverParam = 0;

        if (isSelectingLongVoxel || tempHighlightBox) {
            const totalColsVisible = visibleArea.toCol - visibleArea.fromCol;
            if (totalColsVisible > 0) {
                // Gather all relevant voxels
                const selectedVoxels: BoundingBox[] = [];
        
                // Add voxel from editing mode if active
                if (isSelectingLongVoxel) {
                    const voxel = voxels.find(v => v.id === isSelectingLongVoxel.id && v.type === "longVoxel");
                    if (voxel) selectedVoxels.push(voxel);
                }
        
                // Include all tempHighlightBox types (no type restriction)
                if (!editingMode && tempHighlightBox) {
                    selectedVoxels.push(tempHighlightBox);
                }
        
                // Iterate through each selected voxel
                selectedVoxels.forEach(voxel => {
                    // Validate voxel properties
                    if (!voxel.start || typeof voxel.start.col !== "number" || !voxel.end || typeof voxel.end.col !== "number") {
                        console.error("Incomplete voxel data:", voxel);
                        return;
                    }
        
                    // Determine direction for each voxel
                    let direction: "positive" | "negative" | "none" = "none";
        
                    if (isSelectingLongVoxel && voxel.id === isSelectingLongVoxel.id) {
                        if (isSelectingLongVoxel.endIdx > isSelectingLongVoxel.startIdx) {
                            direction = "positive";
                        } else if (isSelectingLongVoxel.endIdx < isSelectingLongVoxel.startIdx) {
                            direction = "negative";
                        }
                    } else {
                        if (voxel.end.col > voxel.start.col) {
                            direction = "positive";
                        } else if (voxel.end.col < voxel.start.col) {
                            direction = "negative";
                        }
                    }

                    if(voxel.type === "longVoxel"){

                        if (direction === "positive") {
                            const coordRel = voxel.end.col - visibleArea.fromCol;
                            hoverParam = MAIN_CANVAS_VERTICAL_AXIS_SIZE + (coordRel / totalColsVisible) * drawableWidth;
                            ctx.strokeStyle = "#FF0000"; // Red for positive direction
                        } else if (direction === "negative") {
                            const coordRel = voxel.start.col - visibleArea.fromCol;
                            hoverParam = MAIN_CANVAS_VERTICAL_AXIS_SIZE + (coordRel / totalColsVisible) * drawableWidth;
                            ctx.strokeStyle = "#00FF00"; // Green for negative direction
                        } else {
                            const coordRel = voxel.end.col - visibleArea.fromCol;
                            hoverParam = MAIN_CANVAS_VERTICAL_AXIS_SIZE + (coordRel / totalColsVisible) * drawableWidth;
                            ctx.strokeStyle = "#FFFF00"; // Yellow for no movement
                        }

                    }

                    else{
                        const coordRel = voxel.end.col - visibleArea.fromCol;
                        hoverParam = MAIN_CANVAS_VERTICAL_AXIS_SIZE + (coordRel / totalColsVisible) * drawableWidth;
                        ctx.strokeStyle = "#FFFF00"; // Yellow for no movement
                    }
        


                });
            }
        } else {            
            // Default hover behavior when not selecting or highlighting
            hoverParam = MAIN_CANVAS_VERTICAL_AXIS_SIZE + hoverCoordinates.x * scale.x + offset.x;
            ctx.strokeStyle = "#FFFF00"; // Yellow
        }

        // Draw the line
        drawVerticalLine(ctx, hoverParam, 0, PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE);

    }, [avgData, maxData, heatmapData, width, PSD_HEIGHT, visibleArea, canvasSize, minDb, maxDb, voxels, tempHighlightBox]);

    return (
        <canvas ref={canvasRef} className="psd-diagram"/>
    );
};
export default PSDDiagram;
