import { API_URL, version } from "../../utils/constants";
import { formatAsMDY, formatPath, voxelTransformator } from "../../utils/utils";
import { SelectedVoxelInfo } from "../SelectedVoxelInfo";
import React, { useEffect } from "react";
import { useCacheStore } from "../../utils/store";
import { DropDown } from "./DropDown";

export const InfoMenu = () => {
    const heatmapData = useCacheStore(state => state.heatmapData);
    const selectedPath = useCacheStore(state => state.selectedPath);
    const toolMode: TOOL_MODE = useCacheStore(state => state.toolMode);
    const setToolMode: (mode: TOOL_MODE) => void = useCacheStore(state => state.setTooMode);
    const measurements = useCacheStore(state => state.measurements);
    const selectedMeasurement = useCacheStore(state => state.selectedMeasurement);
    const setSelectedMeasurement = useCacheStore(state => state.setSelectedMeasurement);
    const initVoxels = useCacheStore(state => state.initVoxels);
    const getNextBoxId = useCacheStore(state => state.getNextBoxId);
    const setEditingMode = useCacheStore(state => state.setEditingMode);


    const [mods, setMods] = React.useState<{ value: TOOL_MODE, label: string }[]>([{
        value: "GT",
        label: "Ground truth"
    }]);

    useEffect(() => {
        if (measurements && measurements.length > 0) {
            setMods([{ value: "GT", label: "Ground truth" }, { value: "MEA", label: "Measurements" }, {
                value: "COMP",
                label: "Compare"
            }]);
        } else {
            setMods([{ value: "GT", label: "Ground truth" }]);
        }
    }, [measurements]);

    const voxels = (path: string, measurement: string | null, drawing: DrawingType="continuous"): Promise<BoundingBox[]> => {
        const params = new URLSearchParams({ path: path });
        if (measurement)
            params.append("measurement_name", measurement);
        return fetch(`${API_URL}/tag_info/measurement?${params.toString()}`, { credentials: "include" })
            .then(res => res.json())
            .then(data => {
                if (!heatmapData)
                    return [];
                return voxelTransformator(data, heatmapData.height, getNextBoxId, drawing);
            })
            .catch(() => {
                setSelectedMeasurement(null);
                window.alert("Error fetching measurement data");
                return [];
            });
    };

    const onModeChange = async (mode: TOOL_MODE, measurement:string|null) => {
        setToolMode(mode);
        if (mode === "GT") {
            setSelectedMeasurement(null);
            voxels(selectedPath, null).then(initVoxels);
        } else if (mode === "MEA") {
            if(measurement === null) {
                measurement = measurements[0].name;
            }
            setSelectedMeasurement(measurement);
            voxels(selectedPath, measurement).then(initVoxels);
        } else if (mode === "COMP") {
            if(measurement === null) {
                measurement = measurements[0].name;
            }
            setEditingMode(false);
            setSelectedMeasurement(measurement);
            const gt = await voxels(selectedPath, null);
            const meas = await voxels(selectedPath, measurement, "dashed");
            initVoxels(gt.concat(meas));
        } else {
            console.error("Invalid mode");
        }
    };

    return (
        <>
            <h1>PSD Tagger v{version}</h1>
            {heatmapData && (
                <>
                    <p><b>Sampling rate:</b> {heatmapData.sampling_rate} Hz</p>
                    <p><b>Date:</b> {formatAsMDY(heatmapData.start_time)}</p>
                    <p>
                        <b>Selected recording:</b> <br />
                        <span style={{ whiteSpace: "pre-wrap" }}>
                            {formatPath(selectedPath || "", 35)}
                        </span>
                    </p>
                    <h2>Change tool mode</h2>
                    <DropDown
                        options={mods}
                        onChange={(mode) => onModeChange(mode as TOOL_MODE, selectedMeasurement)}
                        value={toolMode}
                    />
                    {(toolMode === "MEA" || toolMode === "COMP") && (
                        <div style={{ marginTop: "20px" }}>
                            <p>Measurements:</p>
                            <ul style={{ listStyleType: "none", paddingLeft: "10px" }}>
                                {measurements.map(m => (
                                    <li key={m.name} style={{ cursor: "pointer" }}
                                        onClick={() => onModeChange(toolMode, m.name)}>
                                        {m.name === selectedMeasurement ? <b>{m.name}</b> : m.name}
                                        {toolMode==="COMP" && m.name === selectedMeasurement && <span style={{paddingLeft:"10px"}}>-------</span>}

                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <SelectedVoxelInfo />
                </>
            )}
        </>
    );
};