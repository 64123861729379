// Copyright (C) 2024 Xtremis, All rights reserved

import React, { ChangeEvent, useEffect, useState } from "react";
import "./TreeView.css";
import { updateTaggingStatus } from "../utils/apiClient";
import { formatPath } from "../utils/utils";
import { useCacheStore } from "../utils/store";
import { TaggingStatusSelector } from "./TaggingStatusSelector";

export interface TreeNode {
    id: string;
    name: string;
    type: "folder" | "file";
    status: TaggingStatus;
    children?: TreeNode[];
}

interface TreeViewProps {
    data: TreeNode[];
    onFileSelect: (filePath: string) => void;
}

const TreeView: React.FC<TreeViewProps> = ({ data, onFileSelect }) => {
    const [textFilter, setTextFilter] = useState<string>("");
    const [filteredData, setFilteredData] = useState<TreeNode[]>(data);
    const [statusFilter, setStatusFilter] = useState<FilterTaggingStatus>("ALL");
    useEffect(() => {
        setFilteredData(
            data
                .filter((node) => node.name.toLowerCase().includes(textFilter.toLowerCase()))
                .filter((node) => statusFilter === "ALL" || node.status === statusFilter)
        );
    }, [data, textFilter, statusFilter]);
    return (
        <div className="tree-view">
            <div style={{ display: "flex" }}>
                <input value={textFilter}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTextFilter(event.target.value)}
                    placeholder="Filter..." />
                <div style={{marginLeft: "auto"}}>

                    <TaggingStatusSelector status={statusFilter}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => setStatusFilter(e.target.value as FilterTaggingStatus)}
                        showNone={true} />
                </div>

            </div>

            {filteredData.map((node) => (
                <TreeNodeComponent key={node.id} node={node} onFileSelect={onFileSelect} />
            ))}
        </div>
    );
};

interface TreeNodeComponentProps {
    node: TreeNode;
    onFileSelect: (filePath: string) => void;
    level?: number;
    parentPath?: string;
}

const TreeNodeComponent: React.FC<TreeNodeComponentProps> = ({ node, onFileSelect, level = 0, parentPath = "" }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [status, setStatus] = useState<TaggingStatus>(node.status);
    const selectedPath = useCacheStore(state => state.selectedPath);

    const hasChildren = node.type === "folder" && node.children && node.children.length > 0;
    const paddingLeft = 20 * level;

    const handleClick = () => {
        if (node.type === "folder") {
            setExpanded(!expanded);
        } else {
            const filePath = parentPath ? `${parentPath}/${node.name}` : node.name;
            onFileSelect(filePath);
        }
    };

    return (
        <div>
            <div className={`tree-node ${node.type} ${status} ${selectedPath === node.name ? "selected" : ""}`}
                style={{ paddingLeft }} onClick={handleClick}>
                {hasChildren && (
                    <span className={`tree-icon ${expanded ? "expanded" : "collapsed"}`}>{expanded ? "📂" : "📁"}</span>
                )}
                {!hasChildren && <span className="tree-icon">📄</span>}
                <span style={{ whiteSpace: "pre-wrap" }} className="tree-name"> {formatPath(node.name || "", 20)}</span>
                <TaggingStatusSelector
                    status={status}
                    onChange={(e) => {
                        updateTaggingStatus(node.name, e.target.value).catch(() => {
                            window.alert("Error updating tagging status");
                        });
                        setStatus(e.target.value as TaggingStatus);
                    }}
                />
            </div>
            {hasChildren && expanded && (
                <div>
                    {node.children!.map((child) => (
                        <TreeNodeComponent
                            key={child.id}
                            node={child}
                            onFileSelect={onFileSelect}
                            level={level + 1}
                            parentPath={parentPath ? `${parentPath}/${node.name}` : node.name}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default TreeView;
