// Copyright (C) 2024 Xtremis, All rights reserved

import React from "react";

interface DropdownProps {
    options: { value: string; label: string }[];
    onChange?: (selectedValue: string) => void;
    value: string;
}

export const DropDown: React.FC<DropdownProps> = ({options, onChange, value}) => {

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div>
            <select
                id="general-dropdown"
                value={value}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};