import React, { useRef } from "react";
import { useCacheStore } from "../utils/store";

type GradientSliderProps = {
    start: number;
    end: number;
};

const GradientSlider: React.FC<GradientSliderProps> = ({ start, end }) => {
    const sliderRef = useRef<HTMLDivElement>(null);
    const minDb = useCacheStore(state => state.minDb);
    const maxDb = useCacheStore(state => state.maxDb);
    const setMinDb = useCacheStore(state => state.setMinDb);
    const setMaxDb = useCacheStore(state => state.setMaxDb);


    const handleDrag = (e: React.MouseEvent, type: "min" | "max") => {
        if (!sliderRef.current) return;

        const rect = sliderRef.current.getBoundingClientRect();
        const sliderWidth = rect.width;
        const offsetX = Math.max(0, Math.min(e.clientX - rect.left, sliderWidth)); // Clamp offsetX within 0 and sliderWidth
        const value = start + (offsetX / sliderWidth) * (end - start);

        if (type === "min") {
            setMinDb(Math.min(Math.max(value, start), maxDb - 1)); // Clamp value between start and maxDb - 1
        } else {
            setMaxDb(Math.max(Math.min(value, end), minDb + 1)); // Clamp value between minDb + 1 and end
        }
    };

    const gradientStyle = {
        background: `linear-gradient(to right, 
      #000000 ${((minDb - start) / (end - start)) * 100}%, 
      #0000ff ${((minDb - start) / (end - start)) * 100}%,
      #00ffff ${((minDb + (maxDb - minDb) * 0.2 - start) / (end - start)) * 100}%,
      #00ff00 ${((minDb + (maxDb - minDb) * 0.4 - start) / (end - start)) * 100}%,
      #ffff00 ${((minDb + (maxDb - minDb) * 0.6 - start) / (end - start)) * 100}%,
      #ff0000 ${((maxDb - start) / (end - start)) * 100}%
    )`
    };

    return (
        <div style={{ padding: "20px", width: "100%" }}>
            <div style={{ marginBottom: "10px" }}>
                <p>
                    min dB: {minDb.toFixed(2)}
                </p>
                <p>
                    max dB: {maxDb.toFixed(2)}
                </p>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {/* Start Label */}
                <span>{start}</span>

                <div
                    ref={sliderRef}
                    style={{
                        position: "relative",
                        height: "20px",
                        width: "100%",
                        border: "1px solid var(--color-border)",
                        borderRadius: "4px",
                        ...gradientStyle
                    }}
                    onMouseDown={(e) => e.preventDefault()} // Prevent text selection
                >
                    <div
                        style={{
                            position: "absolute",
                            top: "-5px",
                            left: `${((minDb - start) / (end - start)) * 100}%`,
                            transform: "translate(-50%, 0)",
                            width: "10px",
                            height: "30px",
                            backgroundColor: "blue",
                            cursor: "pointer"
                        }}
                        onMouseDown={() => {
                            const onMouseMove = (event: MouseEvent) =>
                                handleDrag(event as unknown as React.MouseEvent, "min");
                            const onMouseUp = () => {
                                document.removeEventListener("mousemove", onMouseMove);
                                document.removeEventListener("mouseup", onMouseUp);
                            };
                            document.addEventListener("mousemove", onMouseMove);
                            document.addEventListener("mouseup", onMouseUp);
                        }}
                    />

                    <div
                        style={{
                            position: "absolute",
                            top: "-5px",
                            left: `${((maxDb - start) / (end - start)) * 100}%`,
                            transform: "translate(-50%, 0)",
                            width: "10px",
                            height: "30px",
                            backgroundColor: "red",
                            cursor: "pointer"
                        }}
                        onMouseDown={() => {
                            const onMouseMove = (event: MouseEvent) =>
                                handleDrag(event as unknown as React.MouseEvent, "max");
                            const onMouseUp = () => {
                                document.removeEventListener("mousemove", onMouseMove);
                                document.removeEventListener("mouseup", onMouseUp);
                            };
                            document.addEventListener("mousemove", onMouseMove);
                            document.addEventListener("mouseup", onMouseUp);
                        }}
                    />
                </div>

                {/* End Label */}
                <span>{end}</span>
            </div>
        </div>
    );
};

export default GradientSlider;
